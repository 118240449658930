<template>
  <!-- 绑定关系查询页面 -->
  <div class="bind">
    <!-- 筛选区域 -->
    <div class="screening">
      <el-dialog
          title="查询"
          v-model="theQueryDialog"
          :close-on-click-modal="false"
          :destroy-on-close="true"
          width="30%"
        >
          <div class="">
            <el-form
              label-position="left"
              label-width="100px"
              size="small"
              :model="searchForm"
              ref="upform"
            >
              <div class="form-out-box p-0-30">
                <el-form-item label="服务商：" prop="">
                  <el-select
                    v-model="searchForm.amountId"
                    placeholder="请选择服务商"
                    style="width: 100%"
                    clearable
                    @change="changeValues"
                    size="mini"
                  >
                    <el-option
                      v-for="(item, index) in amount_id"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="接口类型：" prop="">
                  <el-select
                    v-model="searchForm.interfaceId"
                    placeholder="请选择服务商"
                    style="width: 100%"
                    clearable
                    size="mini"
                  >
                    <el-option
                      v-for="(item, index) in interface_list"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="绑&ensp;定&ensp;ID：" prop="">
                  <el-input size="mini" v-model="searchForm.subId" placeholder="请输入绑定ID"></el-input>
                </el-form-item>
                <el-form-item label="用&ensp;&ensp;户：" prop="">
                  <el-select
                    v-model="searchForm.uid"
                    placeholder="请选择"
                    style="width: 100%"
                    clearable
                    filterable 
                  >
                    <el-option
                      v-for="(item, index) in user_list"
                      :key="index"
                      :label="item.nickname"
                      :value="item.uid"
                    >
                      <div class="flex-nowrap-space-between">
                        <div :class="item.status == 1?'no-red':''">
                          <span>{{ item.nickname }}</span>
                          <span class="ml-5">(Id:{{ item.uid }})</span>
                        </div>
                        <div v-if="item.status == 1" class="no-red">禁</div>
                      </div>
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="创建日期：">
                  <el-date-picker
                    v-model="valueTime"
                    type="daterange"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width: 100%"
                    value-format="YYYY-MM-DD"
                    :default-time="defaultTime"
                    size="mini"
                    :clearable="false"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="被&emsp;&emsp;叫：" prop="">
                  <el-input size="mini" v-model="searchForm.telB" placeholder="请输入被叫号码"></el-input>
                </el-form-item>
                <el-form-item label="企&ensp;业&ensp;号：" prop="">
                  <el-input size="mini" v-model="searchForm.telX" placeholder="请输入企业号码"></el-input>
                </el-form-item>
                <el-form-item label="号码状态：" prop="">
                  <el-select
                    v-model="searchForm.status"
                    placeholder="请选择号码状态"
                    style="width: 100%"
                    clearable
                    size="mini"
                  >
                    <el-option
                      v-for="(item, index) in [
                        '正常',
                        '手动解绑',
                        '自动解绑',
                      ]"
                      :key="index"
                      :label="item"
                      :value="index"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="主&emsp;&emsp;叫：" prop="">
                  <el-input size="mini" v-model="searchForm.telA" placeholder="请输入主叫号码"></el-input>
                </el-form-item>
                <el-form-item label="本地绑定ID：" prop="">
                  <el-input size="mini" v-model="searchForm.id" placeholder="请输入本地绑定ID"></el-input>
                </el-form-item>
              </div>
              <el-form-item>
                <div class="flex-nowrap-flex-end">
                  <el-button
                    class="cancel"
                    plain
                    @click="searchTapReset"
                    round
                  >
                    重置
                  </el-button>
                  <el-button
                    class="confirmAdd"
                    @click="searchListFun"
                    round
                  >
                    查询
                  </el-button>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </el-dialog>
    </div>
    <!-- 操作区域 -->
    <el-row>
      <el-col :span="12">
        <div class="title-name"></div>
      </el-col>
      <el-col :span="12">
        <div class="title-button">
          <div class="ml-10">
            <el-button icon="el-icon-search" class="button-el" size="medium" @click="theQueryDialog=true">查询</el-button>
          </div>
          <div class="ml-10">
            <el-tooltip content="刷新列表" placement="top" effect="light">
              <div class="font-refresh-out flex-nowrap-center-center" @click="searchTapReset(1)">
                <icon-font v-if="loading==false" type="icon-shuaxin" class="font-refresh"/>
                <i v-else class="el-icon-loading loding-color"></i>
              </div>
            </el-tooltip>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 数据展示 -->
    <el-row>
      <el-col>
        <div style="overflow-x: auto">
          <el-table
            v-loading="loading"
            size="mini"
            :row-style="{height:'50px'}"
            :cell-style="{padding:'0px'}"
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%; overflow: auto"
            :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
          >
            <!-- <el-table-column label="用户ID" width="100px">
              <template #default="scope">{{
                scope.row.uid == null ? "-" : scope.row.uid
              }}</template>
            </el-table-column> -->
            <el-table-column 
            fixed
            width="140"
            label="服务商">
              <template #default="scope">
                <span>{{ amountText(scope.row.amountId) }}</span>
              </template>
            </el-table-column>
            <el-table-column 
            fixed
            label="接口类型" 
            width="140">
              <template #default="scope">
                <span>{{ interfaceText(scope.row.interfaceId) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="本地绑定ID" width="160px">
              <template #default="scope">{{
                scope.row.id == null ? "-" : scope.row.id
              }}</template>
            </el-table-column>
            <el-table-column 
            width="270"
            label="上游绑定ID">
              <template #default="scope">{{
                scope.row.subId == null ? "-" : scope.row.subId
              }}</template>
            </el-table-column>
            <el-table-column 
            width="160"
            label="用户">
              <template #default="scope">{{
                scope.row.nickName == null ? "-" : scope.row.nickName
              }}</template>
            </el-table-column>
            <el-table-column 
            width="160"
            label="主叫">
              <template #default="scope">{{
                scope.row.telA == null ? "-" : scope.row.telA
              }}</template>
            </el-table-column>
            <el-table-column 
            width="160"
            label="被叫">
              <template #default="scope">{{
                scope.row.telB == null ? "-" : scope.row.telB
              }}</template>
            </el-table-column>
            <el-table-column 
            width="160"
            label="企业号">
              <template #default="scope">{{
                scope.row.telX == null ? "-" : scope.row.telX
              }}</template>
            </el-table-column>
            <el-table-column 
            width="180"
            label="绑定">
              <template #default="scope">
                <div class="grid">
                  绑定：{{ scope.row.bindTime == null ? "-" : scope.row.bindTime }}
                </div>
              </template>
            </el-table-column>
            <el-table-column 
            width="180"
            label="解绑">
              <template #default="scope">
                <div class="grid">
                  解绑：{{ scope.row.unbindTime == null ? "-" : scope.row.unbindTime }}
                </div>
              </template>
            </el-table-column>
            <el-table-column 
            width="180"
            label="添加">
              <template #default="scope">
                <div class="grid">
                  添加：{{ scope.row.createTime == null ? "-" : scope.row.createTime }}
                </div>
              </template>
            </el-table-column>
            <el-table-column 
            width="180"
            label="到期">
              <template #default="scope">
                <div class="grid">
                  到期：{{ scope.row.expireTime == null ? "-" : scope.row.expireTime }}
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column label="更新时间" width="160px">
              <template #default="scope">{{
                scope.row.updateTime == null ? "-" : scope.row.updateTime
              }}</template>
            </el-table-column> -->
            <el-table-column 
            fixed="right"
            label="状态">
              <template #default="scope">
                <span :class="$filter.bindingClass(scope.row.status,scope.row.expireTime)">{{ scope.row.status == null ? "-" : $filter.bindingState(scope.row.status,scope.row.expireTime) }}</span>
              </template>
            </el-table-column>
            <el-table-column 
            fixed="right"
            label="操作">
              <template #default="scope">
                <el-tooltip content="号码解绑" placement="top" effect="light">
                  <el-button
                    class="editor"
                    size="mini"
                    icon="el-icon-unlock"
                    @click="numberUnbound(scope.row)"
                    round
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- 页码 -->
    <div class="pager">
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          background
          :page-sizes="[10, 20, 30, 40]"
          :page-size="searchForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import bind from '@/api/open/privacy/bind'
import interfacets from '@/api/open/privacy/interface'
import amountts from '@/api/open/privacy/amount'
import callts from '@/api/open/privacy/call'
import { message } from "ant-design-vue";
import userts from "@/api/web/user";
import { IconFont } from "@/utils/iconfont";
import { getDate } from '@/utils/timeToChoose.js'
export default {
  name: "Bind",
  components: {
    IconFont
  },
  data() {
    return {
      tableData: [], //当前页面数据
      activeNames: ['1'], //折叠面板
      loading: true, // loding 状态启用
      tableHeight: "", //表格高度
      // operationType: 0, //弹窗提交按钮文字状态
      currentPage: 1, // 分页
      currentStatus: true, // 分页状态
      total: 0, //总数据条数
      interface_id: [], // 拿到的接口类型
      interface_list: [], // 
      amount_id: [], // 拿到的通信账户类型
      valueTime: [], //选中时间段
      theQueryDialog: false,
      defaultTime: [
        new Date(2000, 1, 1),
        new Date(2000, 2, 1),
      ], //默认时间 '12:00:00', '08:00:00'
      searchForm: {
        // 搜索项
        page:1,
        pageSize:10,
        uid: "", // 用户ID
        interfaceId: "", // 接口类型
        amountId: "", // 通信账户ID
        numberId: "", // 隐私号ID
        userNumberID: "", // 用户隐私号ID
        callId: "", // 通话ID
        subId: "", // 绑定ID
        telA: "", // 主叫
        telX: "", // 企业号
        telB: "", // 被叫
        notifyUrl: "", // 回调地址
        notifyData: "", // 回调参数
        bindTime: "", // 绑定时间
        expireTime: "", // 到期时间
        unbindTime: "", // 解绑时间
        bindTimeBegin: "", // 添加时间
        bindTimeEnd: "", // 更新时间
        status: "", // 状态(0:正常/1:手动解绑/2.自动解绑)
      },
      user_list:[],
    };
  },
  mounted(){
    this.searchForm.createTimeBegin = getDate(0)
    this.searchForm.createTimeEnd = getDate(0)
    this.valueTime[0] = getDate(0)
    this.valueTime[1] = getDate(0)
    this.getDataList();
    this.getApiType();
    this.getAmountType();
    this.getUserList()
    this.tableHeight = this.$store.state.tableHeight;
  },
  methods: {
     // 选中服务商
    changeValues(val){
      this.searchForm.interfaceId = ''
      interfacets.getInterface({
        amountId:val
      }).then((res) => {
        this.interface_list = res.data
      });
    },
    // 号码解绑
    numberUnbound(val){
      let bindId = val.id;
      callts.interfaceUnbound({bindId}).then(res =>{
        if(res.code===200){
          message.success('解绑成功');
        }else{
          message.error(res.data);
        }
      })
    },
    // 条件筛选
    searchListFun() {
      if(this.valueTime){
        this.searchForm.createTimeBegin = this.valueTime[0];
        this.searchForm.createTimeEnd = this.valueTime[1];
      }else{
        this.searchForm.createTimeBegin = '';
        this.searchForm.createTimeEnd = '';
      }
      this.getDataList();
    },
    // 获取用户列表
    getUserList() {
      userts.queryList({}).then((res) => {
        this.user_list = res.data;
      });
    },
    // 条件重置
    searchTapReset(kind) {
      this.searchForm = {
        page: this.currentPage,
        pageSize: 10,
      };
      if (kind != 1 || this.valueTime == null || this.valueTime.length == 0) {
        this.valueTime = [getDate(0), getDate(0)]
      }
      this.searchForm.createTimeBegin = this.valueTime[0]
      this.searchForm.createTimeEnd = this.valueTime[1]
      if(kind == 1){
        this.getDataList();
        this.theQueryDialog = false
      }
    },
    // 接口类型汉字
    interfaceText(id) {
      for (let i of this.interface_id) {
        if (i.id === id) {
          return i.name;
        }
      }
    },
    // 通信账户汉字
    amountText(id) {
      if (id == 0) {
        return "主账号";
      } else {
        for (let i of this.amount_id) {
          if (i.id === id) {
            return i.name;
          }
        }
      }
    },
    // 分页--条数
    handleSizeChange(val) {
        this.searchForm.pageSize = val;
        this.getDataList()
    },
    // 分页--页码
    handleCurrentChange(val) {
        this.searchForm.page = val;
        this.currentPage = val;
        this.getDataList()
    },
    // 获取当前页面数据
    getDataList() {
      this.loading = true;
      bind.getBindList({ ...this.searchForm }).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total
        if (res.code === 200) {
          this.loading = false;
        }
      });
    },
    // 获取接口类型数组
    getApiType() {
      interfacets.seeApiList({}).then((res) => {
        this.interface_id = res.data;
        this.interface_list = res.data;
      });
    },
    // 获取通信账户类型
    getAmountType() {
      amountts.getAmountList({}).then((res) => {
        this.amount_id = res.data;
      });
    },
  },
  created() {
   
  },
};
</script>
<style lang="scss" scoped>
.bind{
  padding-right: 1.125rem;
  // .grid{
    // display: gird;
    // height: 60px;
    // place-items:center;
  // }
}
.title-buttom{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
}
.cancel{
    color: #637DFF;
    border: #637DFF solid 1px;
}
.confirmAdd{
    background: #637DFF;
    color: #fff;
}
</style>